<template>
    <div class="card card-custom">
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ $t("settings.heading.company") }}</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.subheading.company") }}</span>
            </div>
            <div class="card-toolbar">
                <b-button class="btn btn-success mr-2" @click.prevent="saveCompany" ref="save_submit">{{ $t("button.save") }}</b-button>
            </div>
        </div>
        <div class="card-body">
            <ValidationObserver ref="form">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.name">{{ $t("label.company_name") }}</label>
                <div class="col-lg-9 col-xl-6">            
                    <ValidationProvider>
                    <input 
                        name="company.name"
                        id="company.name"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.company_name')"
                        v-model="company.company"
                        >                
                    </ValidationProvider>    
                </div>
              </div>

               <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.in">{{ $t("label.company_in") }}</label>
                    <div class="col-lg-5 col-xl-4">            
                        <ValidationProvider>
                        <input 
                            name="company.in"
                            id="company.in"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('placeholder.company_in')"
                            v-model="company.in"
                            >    
                        </ValidationProvider>    
                    </div>
                    <div class="col-lg-4 col-xl-2">
                        <button class="col-xl-12 py-3 btn btn-primary" @click.prevent="loadAresData" ref="load_ares">{{ $t("button.load") }}</button>
                    </div>
                    
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.tin">{{ $t("label.company_tin") }}</label>
                    <div class="col-lg-9 col-xl-6">            
                        <ValidationProvider>
                        <input 
                            name="company.tin"
                            id="company.tin"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('placeholder.company_tin')"
                            v-model="company.tin"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>
              
              <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-6">
                    <h5 class="font-weight-bold mt-10 mb-6">{{ $t("settings.title.default_address") }}</h5>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.street">{{ $t("label.street") }}</label>
                <div class="col-lg-9 col-xl-6">            
                    <ValidationProvider>
                    <input 
                        name="company_street"
                        id="company_street"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.street')"
                        v-model="company.street"
                        >                
                    </ValidationProvider>    
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.street">{{ $t("label.house_number") }}</label>
                <div class="col-lg-9 col-xl-6">            
                    <ValidationProvider>
                    <input 
                        name="company_house_number"
                        id="company_house_number"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.house_number')"
                        v-model="company.house_number"
                        >                
                    </ValidationProvider>    
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.city">{{ $t("label.city") }}</label>
                <div class="col-lg-9 col-xl-6">            
                    <ValidationProvider>
                    <input 
                        name="company_city"
                        id="company_city"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.city')"
                        v-model="company.city"
                        >                
                    </ValidationProvider>    
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.zip">{{ $t("label.zip") }}</label>
                <div class="col-lg-9 col-xl-6">            
                    <ValidationProvider>
                    <input 
                        name="company_zip"
                        id="company_zip"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.zip')"
                        v-model="company.zip"
                        >    
                    </ValidationProvider>    
                </div>
              </div>

              <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-6">
                    <h5 class="font-weight-bold mt-10 mb-6">{{ $t("settings.title.contact_info") }}</h5>
                </div>
              </div>
              
              <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.web">{{ $t("label.web") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <ValidationProvider>
                        <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="la la-globe"></i>
                            </span>
                            </div>
                            <input
                            name="company_web"
                            id="company_web"
                            ref="web"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('placeholder.web')"
                            v-model="company.web"
                            />
                        </div>
                        </ValidationProvider>  
                    </div>
                </div>

               <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.email">{{ $t("label.email") }}</label>
                <div class="col-lg-9 col-xl-6">
                    <ValidationProvider>
                    <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                        <i class="la la-at"></i>
                        </span>
                    </div>
                    <input
                        name="company_email"
                        id="company_email"
                        ref="email"
                        type="email"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.email')"
                        v-model="company.email"
                       
                    />
                    </div>
                    </ValidationProvider>  
                </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.phone">{{ $t("label.phone") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <ValidationProvider>
                        <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="la la-phone"></i>
                            </span>
                            </div>
                            <input
                            name="company_phone"
                            id="company_phone"
                            ref="phone"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('placeholder.phone')"
                            v-model="company.phone"
                            />
                        </div>
                        <span class="form-text text-muted">{{ $t("placeholder.phone_format") }}</span>
                        </ValidationProvider>  
                    </div>
                </div>
                <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right" for="company.banknumber">{{ $t("label.banknumber") }}</label>
                <div class="col-lg-6 col-xl-4">            
                    <ValidationProvider name="company.banknumber">
                    <input 
                        name="company_banknumber"
                        id="company_banknumber"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.banknumber')"
                        v-model="company.bank_account"
                        >    
                    </ValidationProvider>    
                </div>
                <div class="col-lg-3 col-xl-2">            
                    <ValidationProvider>
                    <input 
                        name="company_bankcode"
                        id="company_bankcode"
                        type="text" 
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('placeholder.bankcode')"
                        v-model="company.bank_code"
                        >    
                    </ValidationProvider>    
                </div>
              </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  components: {
      
  },
  data() {
    return {
        company: {
            company: null,
            in: null,
            tin: null,
            street: null,
            city: null,
            zip: null,
            house_number: null,
            web: null,
            email: null,
            phone: null,
            bank_account: null,
            bank_code: null,
        }  
    }
  },
  beforeMount() {
    this.getCompany();
  },
    metaInfo () { 
      return { title: this.$t("settings.meta.subtitle.company")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("settings.meta.title"), subtitle: this.$t("settings.meta.subtitle.company") }]);
  },
  methods: {
    loadAresData() {
      const submitButton = this.$refs["load_ares"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/ares/" + this.company.in)
        .then((response) => {
          this.company = response.data.ares;
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(() => {
          this.$toasted.error('Dejv neplatí elektřinu a tak jsme vám nemohli poskytnout data')
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
    },
    getCompany(){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/company")
        .then((response) => {
          this.company = response.data;
          this.loading = false;
        });
    },
    saveCompany(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/company", 
        { 
          'company': this.company.company,
          'in':  this.company.in,
          'tin':  this.company.tin,
          'street': this.company.street,
          'city':  this.company.city,
          'zip':  this.company.zip,
          'house_number': this.company.house_number,
          'web':  this.company.web,
          'email':  this.company.email,
          'phone': this.company.phone,
          'bank_account':  this.company.bank_account,
          'bank_code':  this.company.bank_code,
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            checkErrors(error);
        });
    });
    }
  }
};
</script>
